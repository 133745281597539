import React, { useEffect, useState } from 'react';

import { styled, theme } from '../Theme';
import { pseudo } from '@glitz/core';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import PageModelBase from '../Shared/Models/PageModelBase.interface';
import { useUserStateData } from '../Shared/UserContextProvider/UserContextProvider';
import ArrowLeftIconPrimary from '../Shared/Icons/ArrowLeftIconPrimary';
import CustomerInformationModel from '../MyPagesPage/Models/CustomerInformationModel.interface';
import { GetMyInformation } from '../CustomerInformation/GetCustomerInformation';
import KexLink from '../Shared/KexLink/KexLink';

type PropType = {
  onClick?: () => void;
  order?: any;
  orderConfirmation?: boolean;
};

function OrderInformationMobile({
  onClick,
  order,
  orderConfirmation,
}: PropType) {
  const {
    translations: {
      'order/myOrders/orderDate': orderDateLabel,
      'order/myOrders/orderNumber': orderNumberLabel,
      'order/myOrders/status': statusLabel,
      'miniCart/totalSum': totalSumLabel,
      'checkoutPage/order/freightCost': freightCosLabel,
      'common/information': informationHeadingLabel,
      'common/backLink': backLinkLabel,
      'common/quantity': quantityLabel,
      'common/total': totalLabel,
      'common/price': priceLabel,
      'searchPage/products': productsLabel,
      'order/myOrders/trackingLink': trackingLinkLabel,
    },
    languageRoute,
    requestToken,
  } = useAppSettingsData();

  const { customerId } = useUserStateData();
  const { channelId } = useCurrentPage<PageModelBase>();
  const url = `/api/${languageRoute}/customer/GetMyInformation?customerId=${customerId}`;
  const [customerInformation, setCustomerInformation] = useState<
    CustomerInformationModel
  >();

  useEffect(() => {
    customerId &&
      GetMyInformation(channelId, requestToken, url).then(
        data => data && setCustomerInformation(data)
      );
  }, [channelId]);

  return (
    <>
      <OrderHistoryComponentWrapper>
        {!orderConfirmation && (
          <BackLinkWrapper>
            <ArrowLeftIcon />
            <BackLink onClick={onClick}>{backLinkLabel}</BackLink>
          </BackLinkWrapper>
        )}
        <CustomerInformationComponent>
          <Heading>{informationHeadingLabel}</Heading>
          <Item>{customerInformation?.companyName}</Item>
          <Item>{customerInformation?.deliveryAddress?.street}</Item>
          <Item data-large="true">
            {customerInformation?.deliveryAddress?.postalCode},
            {customerInformation?.deliveryAddress?.city}
          </Item>

          <ItemHeading>{orderNumberLabel}</ItemHeading>
          <Item>{order.externalOrderID}</Item>
          <ItemHeading>{orderDateLabel}</ItemHeading>
          <Item>{order.orderDate}</Item>
          <ItemHeading>{statusLabel}</ItemHeading>
          <Item>{order.status}</Item>
          {order.trackingLink && (
            <>
              <ItemHeading>{trackingLinkLabel}</ItemHeading>
              <TrackingLink href={order.trackingLink}>
                {order.trackingLink}
              </TrackingLink>
            </>
          )}
        </CustomerInformationComponent>

        {order.orderRows.map((orderRow: any) => (
          <OrderItemsTable key={orderRow.name}>
            <Array data-whitebackground="true">
              <ArrayItemHeading>{productsLabel}</ArrayItemHeading>
              <ProductLink href={orderRow.link?.href}>
                {orderRow.name}
              </ProductLink>
            </Array>
            <Array>
              <ArrayItemHeading>{quantityLabel}</ArrayItemHeading>
              <ArrayItem>{orderRow.quantity}</ArrayItem>
            </Array>
            <Array data-whitebackground="true">
              <ArrayItemHeading>{priceLabel}</ArrayItemHeading>
              <ArrayItem>{orderRow.unitPrice}</ArrayItem>
            </Array>
            <Array>
              <ArrayItemHeading>{totalLabel}</ArrayItemHeading>
              <ArrayItem>{orderRow.totalPrice}</ArrayItem>
            </Array>
          </OrderItemsTable>
        ))}

        <BottomSection>
          <BottomSectionTop>
            <BottomSectionLeft>
              <ItemLabel>{totalSumLabel}</ItemLabel>
              <ItemLabel>{freightCosLabel} </ItemLabel>
            </BottomSectionLeft>
            <BottomSectionRight>
              <ItemLabel>{order.orderGrandTotal}</ItemLabel>
              <ItemLabel>{order.orderTotalDeliveryCost}</ItemLabel>
            </BottomSectionRight>
          </BottomSectionTop>
          <TotalSumArea>
            <TotalSum>{totalSumLabel}</TotalSum>
            <TotalSumValue>{order.orderGrandTotal}</TotalSumValue>
          </TotalSumArea>
        </BottomSection>
      </OrderHistoryComponentWrapper>
    </>
  );
}

const OrderHistoryComponentWrapper = styled.div({
  backgroundColor: theme.primaryBackground,
  borderRadius: '10px',
});

const BackLinkWrapper = styled.div({
  display: 'flex',
});

const BackLink = styled.span({
  fontSize: theme.gamma,
  color: theme.linkColor,
  cursor: 'pointer',
  textDecoration: 'underline',
  paddingLeft: '16px',
});

const ArrowLeftIcon = styled(ArrowLeftIconPrimary, {
  color: theme.linkColor,
});

const TrackingLink = styled(KexLink, {
  color: theme.linkColor,
  cursor: 'pointer',
  fontSize: theme.beta,
  marginBottom: '15px',
  textDecoration: 'underline',
  overflowWrap: 'break-word',
});

const CustomerInformationComponent = styled.div({
  padding: { xy: theme.huge },
  borderRadius: '10px',
  marginTop: '25px',
  border: {
    xy: {
      style: 'solid',
      width: theme.tiny,
      color: theme.grayLine,
    },
  },
  marginBottom: theme.productHeading,
});

const Heading = styled.h2({
  fontSize: theme.larger,
  color: theme.black,
  margin: { bottom: '15px' },
});

const ItemHeading = styled.h3({
  color: theme.black,
  fontSize: theme.delta,
  fontWeight: 700,
  marginBottom: '5px',
});

const Item = styled.p({
  color: theme.breadText,
  fontSize: theme.beta,
  marginBottom: '15px',
  ...pseudo([':nth-child(n)[data-large="true"]'], {
    marginBottom: '25px',
  }),
});

const OrderItemsTable = styled.div({
  width: '100%',
  padding: { y: theme.spacing(5) },
  borderTopColor: theme.grayLine,
  borderTopStyle: 'solid',
  borderTopWidth: '1px',
});

const Array = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  lineHeight: '22.4px',
  padding: { x: theme.spacing(4), y: '10px' },
  ...pseudo([':nth-child(n)[data-whitebackground="true"]'], {
    backgroundColor: theme.white,
  }),
});

const ArrayItem = styled.span({
  fontSize: theme.gamma,
  textAlign: 'right',
  wordBreak: 'break-word',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color: theme.black,
});

const ProductLink = styled.a({
  fontSize: theme.gamma,
  textAlign: 'right',
  wordBreak: 'break-word',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color: theme.linkColor,
  cursor: 'pointer',
  textDecoration: 'underline',
});

const ArrayItemHeading = styled.span({
  color: theme.breadText,
  fontSize: theme.beta,
  marginRight: '10px',
});

const TotalSumArea = styled.div({
  color: theme.black,
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: theme.spacing(5),
  borderTop: {
    style: 'solid',
    width: theme.tiny,
    color: theme.grayLine,
  },
});

const BottomSection = styled.div({
  backgroundColor: theme.white,
  padding: {
    x: theme.spacing(6),
    top: theme.spacing(4),
    bottom: theme.spacing(7),
  },
  borderRadius: '10px',
});

const BottomSectionTop = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

const BottomSectionLeft = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

const BottomSectionRight = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
});

const ItemLabel = styled.span({
  marginBottom: theme.spacing(6),
  fontSize: theme.gamma,
});

const TotalSum = styled.span({
  fontSize: theme.epsilon,
  fontWeight: 700,
});

const TotalSumValue = styled.span({
  fontSize: theme.delta,
  fontWeight: 700,
});

export default OrderInformationMobile;
