import React, { useEffect, useState } from 'react';

import { styled, theme } from '../Theme';
import Divider from '../Shared/Divider/Divider';
import { pseudo } from '@glitz/core';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import PageModelBase from '../Shared/Models/PageModelBase.interface';
import { useUserStateData } from '../Shared/UserContextProvider/UserContextProvider';
import ArrowLeftIconPrimary from '../Shared/Icons/ArrowLeftIconPrimary';
import CustomerInformationModel from '../MyPagesPage/Models/CustomerInformationModel.interface';
import { GetMyInformation } from '../CustomerInformation/GetCustomerInformation';
import KexLink from '../Shared/KexLink/KexLink';

type PropType = {
  onClick?: () => void;
  order?: any;
  orderConfirmation?: boolean;
};

function OrderInformation({ onClick, order, orderConfirmation }: PropType) {
  const {
    translations: {
      'order/myOrders/orderDate': orderDateLabel,
      'order/myOrders/orderNumber': orderNumberLabel,
      'order/myOrders/status': statusLabel,
      'miniCart/totalSum': totalSumLabel,
      'checkoutPage/order/freightCost': freightCosLabel,
      'common/information': informationHeadingLabel,
      'common/backLink': backLinkLabel,
      'common/quantity': quantityLabel,
      'common/total': totalLabel,
      'common/price': priceLabel,
      'searchPage/products': productsLabel,
      'order/myOrders/trackingLink': trackingLinkLabel,
    },
    languageRoute,
    requestToken,
  } = useAppSettingsData();
  const { customerId } = useUserStateData();
  const { channelId } = useCurrentPage<PageModelBase>();
  const url = `/api/${languageRoute}/customer/GetMyInformation?customerId=${customerId}`;
  const [customerInformation, setCustomerInformation] = useState<
    CustomerInformationModel
  >();

  useEffect(() => {
    customerId &&
      GetMyInformation(channelId, requestToken, url).then(
        data => data && setCustomerInformation(data)
      );
  }, [channelId]);

  return (
    <>
      <OrderHistoryComponentWrapper data-orderconfirmation={orderConfirmation}>
        {!orderConfirmation && (
          <BackLinkWrapper>
            <ArrowLeftIcon />
            <BackLink onClick={onClick}>{backLinkLabel}</BackLink>
          </BackLinkWrapper>
        )}
        <CustomerInformationComponent>
          <LeftSection>
            <Heading>{informationHeadingLabel}</Heading>
            <Item>{customerInformation?.companyName}</Item>
            <Item>{customerInformation?.deliveryAddress?.street}</Item>
            <Item>
              {customerInformation?.deliveryAddress?.postalCode},{' '}
              {customerInformation?.deliveryAddress?.city}
            </Item>
          </LeftSection>

          <RightSection>
            <ItemHeading>{orderNumberLabel}</ItemHeading>
            <Item>{order.externalOrderID}</Item>
            <ItemHeading>{orderDateLabel}</ItemHeading>
            <Item>{order.orderDate}</Item>
            <ItemHeading>{statusLabel}</ItemHeading>
            <Item>{order.status}</Item>
            {order.trackingLink && (
              <>
                <ItemHeading>{trackingLinkLabel}</ItemHeading>
                <TrackingLink href={order.trackingLink}>
                  {order.trackingLink}
                </TrackingLink>
              </>
            )}
          </RightSection>
        </CustomerInformationComponent>

        <OrderItemsTable>
          <ArrayDesktopHeading>
            <ArrayItemHeading>{productsLabel}</ArrayItemHeading>
            <ArrayItemHeading>{quantityLabel}</ArrayItemHeading>
            <ArrayItemHeading>{priceLabel}</ArrayItemHeading>
            <ArrayItemHeading>{totalLabel}</ArrayItemHeading>
          </ArrayDesktopHeading>
          {order.orderRows.map((orderRow: any) => (
            <ArrayDesktopItems key={orderRow.name}>
              <ProductLink href={orderRow.link?.href}>
                {orderRow.name}
              </ProductLink>
              <ArrayItem>{orderRow.quantity}</ArrayItem>
              <ArrayItem>{orderRow.unitPrice}</ArrayItem>
              <ArrayItem>{orderRow.totalPrice}</ArrayItem>
            </ArrayDesktopItems>
          ))}
        </OrderItemsTable>

        <BottomSection>
          <BottomSectionTop>
            <BottomSectionLeft>
              <ItemLabel>{totalSumLabel}</ItemLabel>
              <ItemLabel>{freightCosLabel} </ItemLabel>
            </BottomSectionLeft>
            <BottomSectionRight>
              <ItemLabel>{order.orderGrandTotal}</ItemLabel>
              <ItemLabel>{order.orderTotalDeliveryCost}</ItemLabel>
            </BottomSectionRight>
          </BottomSectionTop>
          <StyledDivider />
          <TotalSumArea>
            <TotalSum>{totalSumLabel}</TotalSum>
            <TotalSumValue>{order.orderGrandTotal}</TotalSumValue>
          </TotalSumArea>
        </BottomSection>
      </OrderHistoryComponentWrapper>
    </>
  );
}

const OrderHistoryComponentWrapper = styled.div({
  backgroundColor: theme.primaryBackground,
  borderRadius: '10px',
  maxWidth: '773px',
  ...pseudo([':nth-child(n)[data-orderconfirmation="true"]'], {
    maxWidth: '900px',
    margin: { x: 'auto' },
  }),
});

const CustomerInformationComponent = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  padding: { xy: theme.theta },
  borderRadius: '10px',
  marginTop: '45px',
  border: {
    xy: {
      style: 'solid',
      width: theme.tiny,
      color: theme.grayLine,
    },
  },
  marginBottom: theme.productHeading,
});

const ProductLink = styled.a({
  fontSize: theme.gamma,
  textAlign: 'right',
  wordBreak: 'break-word',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color: theme.linkColor,
  cursor: 'pointer',
  textDecoration: 'underline',
  lineHeight: '25.6px',
});

const BackLinkWrapper = styled.div({
  display: 'flex',
});

const BackLink = styled.a({
  fontSize: theme.gamma,
  color: theme.linkColor,
  cursor: 'pointer',
  textDecoration: 'underline',
  paddingLeft: '16px',
});

const ArrowLeftIcon = styled(ArrowLeftIconPrimary, {
  fill: theme.linkColor,
});

const Heading = styled.h2({
  fontSize: theme.zeta,
  color: theme.black,
  margin: { bottom: '15px' },
});

const LeftSection = styled.div({});

const ItemHeading = styled.h3({
  color: theme.black,
  fontSize: theme.delta,
  fontWeight: 700,
  marginBottom: '5px',
});

const Item = styled.p({
  color: theme.breadText,
  fontSize: theme.beta,
  marginBottom: '15px',
});

const TrackingLink = styled(KexLink, {
  color: theme.linkColor,
  cursor: 'pointer',
  fontSize: theme.beta,
  marginBottom: '15px',
  textDecoration: 'underline',
  overflowWrap: 'break-word',
});

const ArrayItemHeading = styled.span({
  color: theme.breadText,
  fontSize: theme.beta,
  paddingBottom: theme.spacing(3),
  display: 'table-cell',
  textAlign: 'center',
  lineHeight: '22.4px',
  ...pseudo([':first-of-type'], {
    width: '50%',
    textAlign: 'unset',
  }),
});

const RightSection = styled.div({
  maxWidth: '200px',
});

const TotalSumArea = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

const BottomSection = styled.div({
  color: theme.black,
  backgroundColor: theme.white,
  padding: {
    x: theme.spacing(7),
    top: theme.spacing(4),
    bottom: theme.spacing(7),
  },
  marginBottom: '10px',
  borderRadius: '10px',
});

const BottomSectionTop = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

const BottomSectionLeft = styled.div({});

const BottomSectionRight = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
});

const ItemLabel = styled.p({
  marginBottom: '20px',
  fontSize: theme.gamma,
});

const TotalSum = styled.p({
  fontSize: theme.epsilon,
  fontWeight: 700,
});

const TotalSumValue = styled.p({
  fontSize: theme.delta,
  fontWeight: 700,
});

const OrderItemsTable = styled.div({
  display: 'table',
  width: '100%',
  borderCollapse: 'collapse',
  marginBottom: theme.large,
  tableLayout: 'fixed',
});

const ArrayDesktopHeading = styled.div({
  display: 'table-row',
  borderBottomColor: theme.grayLine,
  borderBottomStyle: 'solid',
  borderBottomWidth: '1px',
});

const ArrayDesktopItems = styled.div({
  display: 'table-row',
  borderBottomColor: theme.grayLine,
  borderBottomStyle: 'solid',
  borderBottomWidth: '1px',
  ...pseudo([':last-of-type'], {
    borderBottomStyle: 'none',
  }),
});

const ArrayItem = styled.span({
  fontSize: theme.gamma,
  color: theme.black,
  padding: { y: theme.spacing(3) },
  display: 'table-cell',
  lineHeight: '25.6px',
  textAlign: 'center',
});

const StyledDivider = styled(Divider, {
  width: '100%',
  backgroundColor: theme.grayLine,
  marginTop: '0px',
});

export default OrderInformation;
