import React from 'react';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import OrderConfirmationPageModel from './Models/OrderConfirmationPageModel.interface';
import { styled, theme } from '../Theme';
import { media } from '@glitz/core';
import OrderInformationMobile from '../OrderHistory/OrderInformationMobile';
import OrderInformation from '../OrderHistory/OrderInformation';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import { mediaQuery } from '../Theme/mediaQueries';
import useMedia from '../Shared/Hooks/useMedia';
import { GetCart } from '../Cart/Cart';

function OrderConfirmationPage() {
  const { order, pageHeading, subHeading } = useCurrentPage<
    OrderConfirmationPageModel
  >();

  GetCart();

  const {
    translations: {
      'checkoutPage/items': itemsLabel,
      'checkoutPage/delivery': deliveryLabel,
      'checkoutPage/free': freeLabel,
      'checkoutPage/total': totalLabel,
      'checkoutPage/subtotal': subtotalLabel,
      'checkoutPage/totalsum': totalsumLabel,
      'checkoutPage/ship': shipLabel,
      'checkoutPage/ordernumber': ordernumberLabel,
      'checkoutPage/deliveryaddress': deliveryaddressLabel,
    },
  } = useAppSettingsData();

  const isDesktop = useMedia(theme.mediaQuery.mediaMinLarge);

  return (
    <main>
      {order ? (
        <PageWrapper>
          <Heading>{pageHeading} </Heading>
          <Intro
            dangerouslySetInnerHTML={{
              __html: subHeading,
            }}
          />
          {isDesktop ? (
            <OrderInformation order={order} orderConfirmation />
          ) : (
            <OrderInformationMobile order={order} orderConfirmation />
          )}
        </PageWrapper>
      ) : (
        <></>
      )}
    </main>
  );
}

export default OrderConfirmationPage;

const PageWrapper = styled.div({
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(20),
  padding: {
    x: '7.5px',
  },
  ...media(mediaQuery.mediaMinLarge, {
    maxWidth: '900px',
    margin: { x: 'auto' },
    padding: {
      x: 0,
    },
  }),
});

const Heading = styled.h1({
  color: theme.black,
  font: { size: theme.psi, weight: theme.fontWeight.bold },
  marginBottom: theme.spacing(5),
  ...media(theme.mediaQuery.mediaMinLarge, {
    fontSize: '32px',
    lineHeight: '40px',
    marginTop: theme.spacing(5),
  }),
});

const Intro = styled.p({
  color: theme.black,
  lineHeight: '28.8px',
});
